<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <h1>Forgot password</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <ErrorFormField :msg-type="$data.result.status" :msg-array="$data.result.messages" />
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            id="email"
            placeholder="Input your register email"
          />
          <span class="input-group-text">
            <div
              class="spinner-border spinner-border-sm text-secondary"
              role="status"
              v-if="$data.ajax.isSubmitting"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <i class="fas fa-mail-bulk text-danger" v-else></i>
          </span>
        </div>
        <ErrorFormField :msg-array="$data.errors.email" />
        <div class="mt-3">
          <LoadingIcons v-if="$data.ajax.isSubmitting" />
          <button
            class="btn btn-primary w-100"
            id="btnLoginModal"
            @click="btnVerifyClick"
            type="submit"
            v-else
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIcons from '../../common/LoadingIcons.vue';
import ErrorFormField from '../../common/error/ErrorFormField.vue';
export default {
  name: 'ForgotPassword',
  components: { LoadingIcons, ErrorFormField },
  data: function () {
    return {
      ajax: {
        isSubmitting: false,
      },
      result: {
        status: '',
        messages: [],
      },
      errors: {
        email: [],
      },
    };
  },

  methods: {
    btnVerifyClick: async function () {
      let email = document.getElementById('email').value;
      this.$data.ajax.isSubmitting = true;
      let self = this;
      // console.log("state $store ", this.$store.state)
      let apiUrl = process.env.VUE_APP_API_URL;

      await fetch(apiUrl + 'user/forgot-password', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: 'email=' + encodeURI(email),
      })
        .then((response) => response.json())
        .then((resData) => {
          let messages = resData.messages;

          if (resData.status == 'error') {
            if (messages.email.length > 0) self.$data.errors.email = messages.email;
            else self.$data.errors.email = [];
            self.$data.ajax.isSubmitting = false;
            return false;
          }
          self.$data.result.messages = messages.common;
          self.$data.result.status = resData.status;
          self.$data.ajax.isSubmitting = false;
        })
        .catch(function (err) {
          console.log('error', err);
          self.$data.ajax.isSubmitting = false;
        });
    },
  },
  mounted() {
    if (this.$store.state.active == 1) {
      this.$router.push('/');
      return;
    }
  },
};
</script>

<style scoped></style>
